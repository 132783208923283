@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600');

*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #2d2d2d;
  transition: all 0.15s;
}
html, body, #root{height: 100%;}
body{ background: #ffffff; }

a{text-decoration: none;}

#root{
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.bold{
  font-weight: bold;
}

.active{cursor: pointer;}
.action-btn-text {
  font-size: 0.775rem;
  text-transform: uppercase;
  color: #BA1F00;
  font-weight: bold;
}
.action-btn, .action-btn{
  height: 36px;
  line-height: 36px;
  /* border-radius: 18px; */
  padding: 0px 24px;
}
.action-btn.active.inverted, .action-btn.active.inverted *{
  color: #7CBA00 ;
  background-color: #fff;
}
.action-btn.active.inverted{border: 1px solid #7CBA00;}
.action-btn.active, .action-btn.active *{
  background-color: #7CBA00 ;
  color: #FFF;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
}

.disabled{
  color: gray;
}

.btn-root-light{background-color: #E8CBA6 !important; color: #000!important; border-radius: 0 !important; height: 56px}
.btn-root{background-color: #BA1F00 !important;  border-radius: 0 !important; height: 56px}
.btn-root .btn-text{color: #FFF!important;}