.store-cntr {
  margin: 5rem 125px;
}
.store-cntr-row {
  margin: 0px -1.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.store-product-card {
  position: relative;
  width: 242px;
  height: 300px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  transition: 0.3s ease;
  margin: 1.25rem;
  border: 1px solid #ccc;
}

.icon-cntr {
  border-radius: 12px 12px 0px 0px;
  height: 130px;
  display: flex;
  align-items: flex-end;
}

.store-product-card img {
  max-width: 100%;
}

.overlay {
  border-radius: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.3s ease;
}

.store-product-card:hover .overlay {
  height: 100%;
}
.store-product-card:hover .icon-cntr {
  opacity: 0;
  transition: 0.3s ease;
}

.product-card-back {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.product-card-back .-title {
  color: #333;
  font-size: 1rem;
  font-weight: 700;
  margin: 0rem 0rem 1rem;
}
.product-card-back .-desc {
  color: #666;
  font-size: 0.85rem;
}

.product-card-back-content {
  /*  */
}

.product-card-back .action-cntr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-card-back .action-btn {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.channel-title {
  margin: 2rem 1.125rem;
  font-size: 1.35rem;
}

.section-title {
  font-size: 2rem;
  margin: 3rem 0rem 0.5rem;
}

.section-description {
  margin: 0.5rem 0rem 3rem;
  font-size: 1.125rem;
}

.subscribed-btn {
  margin: 1rem;
  color: #999;
  position: absolute;
  bottom: 0rem;
  right: 0rem;
}
