@import url(https://fonts.googleapis.com/css?family=Merriweather:900i);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,600);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}



.auth-form-cntr{
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.errorMsg{
  height: 1.375em;
}

.login-cntr{
  margin: 2rem auto;
  max-width: 534px;
  padding: 40px 40px 40px 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.loginform-cntr .input-cntr {
    padding: 5px 0 20px 0;
}

.box-title {
    color: #222;
}

.loginform-cntr .box-btn {
    color: #fff;
    background-color: #ba1f00;
}

.loginform-cntr .box-btn:hover {
    background-color: #ba3222;
}
.errorMsg{
  height: 1.375em;
}

.register-cntr{
  margin: 2rem auto;
  max-width: 534px;
  padding: 40px 40px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.loginform-cntr .input-cntr {
    padding: 5px 0 20px 0;
}

.box-title {
    color: #222;
}

.loginform-cntr .box-btn {
    color: #fff;
    background-color: #ba1f00;
}

.loginform-cntr .box-btn:hover {
    background-color: #ba3222;
}

.login-artifact{
  display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}
.\--slide{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.\--slide img, .val-- img{
  max-width:100%;
}

.homepage-title{
  font-size: 1.4rem;
  padding-top: 2rem;
  padding-bottom: 1.8rem;
  line-height: 2rem;
  max-width: 850px;
  margin: auto !important;
  text-align: center;
}
.\--header .action-btn-text, .\--header .action-btn{
  margin: 0px 12px;
}
.homepage-subtitle{
  font-size: 1.3rem;
  line-height: 2rem;
}

.val-cntr{
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
  align-items: flex-end
}

.val--{
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 30px;
}

.val--:not(:last-child){
  border-right: 1px solid #ececec;
  flex-grow: 1;
}

.val--:not(:last-child)::before{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  content: "To Enable my Child";
  font-weight: bold;
}

.val-- ul{
  text-align: left;
  padding: 0px 2rem;
}

.val-- ul {
  list-style: none;
  font-size: 1.2rem;
}
.val-- ul li{
  margin: .5rem 0rem;
}
.val-- ul li:before {
  content: '\2713';
  margin-right: 1rem;
  color: green;
  font-weight: bold;
}

.val-img-cntr{
  width: 60%;
  margin: auto;
  background-color: rgb(65, 171, 69);
  border-radius: 8px;
  height: 96px;
  display: flex;
  align-items: flex-end;
}

.action-btn-cntr{
  display: flex;
}

.video-react{
  background-color: transparent;
  border: 2px solid #FFBC36
}

.video-react .video-react-poster{
  background-color: transparent !important;
}

#section-2, #section3, #section4, #section-5{
  /* max-width: 1200px; margin: auto; */
}

.policies-cntr a {
  line-height: 1.2;
  padding: 4px 8px;
  font-size: 0.75rem !important;
}

.policies-cntr a:not(:last-child) {
  border-right: 1px solid #ccc;
}
.coc li{
  margin-left: 1rem;
}
.channelsCntr {
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  min-height: 470px;
}

.blk-cntr{
  margin: auto;
  max-width: 534px;
  padding: 48px 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}


.blk-cntr .input-cntr { padding: 5px 0 20px 0; }
.blk-cntr .box-btn {
  color: #fff;
  background-color: #ba1f00;
}

.blk-cntr .box-btn:hover {
  background-color: #ba3222;
}

.tabs-indicator{ background-color: #FFBC36 !important; width: 140px !important; height: 4px !important; }

.tabs-root button {min-width: 140px !important; font-weight: 500; width: 140px;}
.tabs-root button span{ font-size: 16px;}
.capsule-cntr{
  border-top: 1px solid #C4C4C4;
  padding: 2.5rem 0rem;
  width: 90%;
  max-width: 1005px;
  margin: auto;
}

.capsule-blk{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.capsule-left{
  width: 55%;
}

.capsule-right{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.-step{
  border-top: 1px solid #E5E5E5;
}
.capsule-right img{
  max-width: 100%;
}
.action-cntr{display: flex; padding-top: 16px;}
.action-btn-capsule{background-color: #7CBA00 !important;}
.action-btn-capsule .btn-text{color: #FFF!important; font-size: 0.8rem;}

#header-bar{
  height: 84px;
  padding: 0px 64px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* box-shadow: 0 0.125rem 0.3125rem rgba(0,0,0,.26); */
  border-bottom: 1px solid #dddddd;
  /* margin-bottom: 5rem; */
}

.site-logo-cntr{
  /* height: 62px; */
  display: flex;
  align-items: flex-end;
  /* margin: 19px 0px; */
  height: 48px;
  margin: 16px;
}

.site-logo-cntr img{
 max-height: 100%;
}

.site-title{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

.merriwether{
  font-family: 'Merriweather', serif;
}

.red{
  color:#FF3D00;
}

#header-bar a{
  color: #2d2d2d;
  cursor: pointer;
}
._dashboardCntr{
  width: 100%;
  flex-grow: 1;
}

.programOverviewTrigger{
  padding: 1rem 2rem;
  border-bottom: 1px solid #ffffff;
}

.programMapCntr{
  background-color: #fff;
  display: flex;
  margin-top: "52.07px"
}
.program-flow-cntr{
  flex-grow: 1;
}
._stepper{
  background-color: inherit !important;
}
.whiteText{
  color: #ffffff !important;
}

.blackText{
  color: rgba(0, 0, 0, 0.87) !important;
  margin-bottom: 0px !important;
}

.step-details-cntr{
  margin: 1rem 3rem 0rem;
}

.step-label{
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  padding-left: 1rem;
  display: inline-block;
}

.-step svg circle{
  color:#CECECE
}


.-step.active svg circle{
  color:#FFBC36
}

.step-label-time{
  font-size: 15px;
  margin-left: 1rem;
  line-height: 1.5;
}
.step-root{
  align-items: flex-start !important;
}
.store-cntr {
  margin: 5rem 125px;
}
.store-cntr-row {
  margin: 0px -1.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.store-product-card {
  position: relative;
  width: 242px;
  height: 300px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  transition: 0.3s ease;
  margin: 1.25rem;
  border: 1px solid #ccc;
}

.icon-cntr {
  border-radius: 12px 12px 0px 0px;
  height: 130px;
  display: flex;
  align-items: flex-end;
}

.store-product-card img {
  max-width: 100%;
}

.overlay {
  border-radius: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.3s ease;
}

.store-product-card:hover .overlay {
  height: 100%;
}
.store-product-card:hover .icon-cntr {
  opacity: 0;
  transition: 0.3s ease;
}

.product-card-back {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.product-card-back .-title {
  color: #333;
  font-size: 1rem;
  font-weight: 700;
  margin: 0rem 0rem 1rem;
}
.product-card-back .-desc {
  color: #666;
  font-size: 0.85rem;
}

.product-card-back-content {
  /*  */
}

.product-card-back .action-cntr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-card-back .action-btn {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.channel-title {
  margin: 2rem 1.125rem;
  font-size: 1.35rem;
}

.section-title {
  font-size: 2rem;
  margin: 3rem 0rem 0.5rem;
}

.section-description {
  margin: 0.5rem 0rem 3rem;
  font-size: 1.125rem;
}

.subscribed-btn {
  margin: 1rem;
  color: #999;
  position: absolute;
  bottom: 0rem;
  right: 0rem;
}

.productPage{
  display: flex;
  justify-content: space-between;
  margin: 0px 125px;
}

.left-col{
  max-width: 475px;
}

.right-col{
  min-width: 550px;
  padding: 0px 0px 0px 4.25rem;
  flex-grow: 1;
}

.ch-title{
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.ch-sub-title{
  font-size: 1.125rem;
  color: #686868;
}

.ch-description{
  margin: 0.5rem 0px;
  font-size: 0.825rem;
  line-height: 1.5;
  color: #505050;
}

.productPage .recommendations{
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  margin: 1.5rem 0px;
  padding: 1rem 0px;
  display: flex;
  justify-content: space-between;
}

.productPage .recommendations span{
  display: block;
}

.productPage .-title{
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

ol.product-offering{
  padding-left: 1.2rem
}

ol.product-offering li{
  padding: 0.5rem
}
*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #2d2d2d;
  transition: all 0.15s;
}
html, body, #root{height: 100%;}
body{ background: #ffffff; }

a{text-decoration: none;}

#root{
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.bold{
  font-weight: bold;
}

.active{cursor: pointer;}
.action-btn-text {
  font-size: 0.775rem;
  text-transform: uppercase;
  color: #BA1F00;
  font-weight: bold;
}
.action-btn, .action-btn{
  height: 36px;
  line-height: 36px;
  /* border-radius: 18px; */
  padding: 0px 24px;
}
.action-btn.active.inverted, .action-btn.active.inverted *{
  color: #7CBA00 ;
  background-color: #fff;
}
.action-btn.active.inverted{border: 1px solid #7CBA00;}
.action-btn.active, .action-btn.active *{
  background-color: #7CBA00 ;
  color: #FFF;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
}

.disabled{
  color: gray;
}

.btn-root-light{background-color: #E8CBA6 !important; color: #000!important; border-radius: 0 !important; height: 56px}
.btn-root{background-color: #BA1F00 !important;  border-radius: 0 !important; height: 56px}
.btn-root .btn-text{color: #FFF!important;}
