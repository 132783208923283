.auth-form-cntr{
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.errorMsg{
  height: 1.375em;
}

.login-cntr{
  margin: 2rem auto;
  max-width: 534px;
  padding: 40px 40px 40px 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.loginform-cntr .input-cntr {
    padding: 5px 0 20px 0;
}

.box-title {
    color: #222;
}

.loginform-cntr .box-btn {
    color: #fff;
    background-color: #ba1f00;
}

.loginform-cntr .box-btn:hover {
    background-color: #ba3222;
}