.productPage{
  display: flex;
  justify-content: space-between;
  margin: 0px 125px;
}

.left-col{
  max-width: 475px;
}

.right-col{
  min-width: 550px;
  padding: 0px 0px 0px 4.25rem;
  flex-grow: 1;
}

.ch-title{
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.ch-sub-title{
  font-size: 1.125rem;
  color: #686868;
}

.ch-description{
  margin: 0.5rem 0px;
  font-size: 0.825rem;
  line-height: 1.5;
  color: #505050;
}

.productPage .recommendations{
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  margin: 1.5rem 0px;
  padding: 1rem 0px;
  display: flex;
  justify-content: space-between;
}

.productPage .recommendations span{
  display: block;
}

.productPage .-title{
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

ol.product-offering{
  padding-left: 1.2rem
}

ol.product-offering li{
  padding: 0.5rem
}