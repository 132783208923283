.channelsCntr {
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  min-height: 470px;
}

.blk-cntr{
  margin: auto;
  max-width: 534px;
  padding: 48px 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}


.blk-cntr .input-cntr { padding: 5px 0 20px 0; }
.blk-cntr .box-btn {
  color: #fff;
  background-color: #ba1f00;
}

.blk-cntr .box-btn:hover {
  background-color: #ba3222;
}

.tabs-indicator{ background-color: #FFBC36 !important; width: 140px !important; height: 4px !important; }

.tabs-root button {min-width: 140px !important; font-weight: 500; width: 140px;}
.tabs-root button span{ font-size: 16px;}